.board {
  padding: 60px 0;
  display: flex;
  gap: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
}
.boardleft {
  align-items: center;
  text-align: justify;
  width: 100%;
}
.boardleft h1 {
  color: var(--magenda);
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.boardleft h2 {
  color: var(--indigo);
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
}
.boardright {
    display: flex;
    justify-content: center;
    align-items: center;
}
.boardright .img {
  width: 130px;
  height: 130px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, darkblue, #ed1968) border-box;
  border-radius: 50%;
  border: 5px solid transparent;
  overflow: hidden;
}

.boardright .img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
