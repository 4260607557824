.head {
  background-color: var(--magenda);
}

/* .head .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
} */

.head .container{
  padding: 15px 0;
}

.container > .nav-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.head_left,
.head_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  width: fit-content;
  /* border: 1px solid red;    */
}

.head_left > a, .head_left_report_tag,
.head_right > a {
  margin-right: 10px;
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
}

.head_left > a, .head_left_report_tag {
  margin-right: 15px;
  cursor: pointer;
}

.head_left_report_tag{
  position: relative;
}
.head_left_report_tag:hover .AnnualyReports{
  display: flex;
}

.AnnualyReports{
  position: absolute;
  left: 0;
  top: 23px;
  /* border: 1px solid black; */
  flex-direction: column;
  width: 200px;
  z-index: 999999999999999;
  border-radius: 5px;
  display: none;
}

.AnnualyReports a{
  background: var(--white);
  padding: 10px 15px;
  font-size: 0.8rem;
  color: var(--indigo);
}

.AnnualyReports a:hover{
  /* border-radius: 5px; */
  background: #eee;
}

.head_right > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_right i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--white);
}

.head_right span {
  color: var(--white);
}

.connect-with-us{
  margin: 0px 5px;
  padding: 5px 10px;
  font-size: 1rem;
  background: var(--white);
  border-radius: 5px;
  color: var(--magenda) !important;
  font-weight: 500;
  border: none;
}

.brochure{
  padding: 5px 10px;
  background: var(--white);
  border-radius: 5px;
  color: var(--magenda) !important;
  font-weight: 500;
  
}


.connect-with-us:hover{
  background-color: #ffffffad;
}


.connect-info{
  position: relative;
}

.contact-list{
  background-color: #eee;
  position: absolute;
  top: 2.3rem;
  width: auto;
  z-index: 999;
}

.contact-list > *{
  color: black;
}

.contact-list li .icon-padding{
  color: black;
  display: flex;
}

.contact-list a{
  color: black;
}

.nav {
  position: absolute;
  z-index: 10;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #ffffff3f;
}

.fixed {
  position: fixed;
  background: var(--white);
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.253);
}

nav .container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
}

.brand img {
  width: 150px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu ul li {
  padding: 15px 10px;
  position: relative;
}

.menu ul li i {
  color: var(--white);
  padding-left: 10px;
}

.fixed .menu ul li i,
.fixed .menu ul li a {
  color: var(--indigo);
}

.menu ul li a {
  font-size: 15px;
  position: relative;
  transition: all 0.5s ease;
  font-weight: 500;
  color: var(--white);
}

.menu ul ul {
  position: absolute;
  top: 50px;
  display: none;
  width: 230px;
  border-radius: 5px;
  background-color: aliceblue;
}

.menu_link {
  cursor: pointer;
}

.menu ul li:hover > ul {
  display: block;
}

.menu ul ul li {
  padding: 10px 20px;
  transition: all 0.5s ease;
}
.menu ul ul li:hover {
  border-radius: 5px;
  background-color: rgb(220, 220, 220);
}

.menu ul ul li a {
  color: var(--indigo);
  font-weight: 400;
  font-size: 13px;
}

.menu-btn {
  display: none;
}
.menu_btn {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--magenda);
  font-size: 18px;
  color: var(--white);
}

.phone_menu_item {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s linear;
  position: absolute;
  z-index: 14;
  top: 100%;
  left: 0;
  min-width: 100%;
}

.phone_menu_item_active {
  /* overflow: scroll; */
  max-height: 1060px;
}

.phone_menu_item a {
  padding: 10px 0;
  font-weight: 300;
  border: 1px solid #b10242;
  width: 100%;
  text-align: center;
  background-color: var(--magenda);
  color: var(--white);
}

.phone_menu_item a i {
  margin-left: 10px;
  color: var(--white);
}

.phone_menu_item a strong {
  color: var(--white);
  font-weight: 500;
}

.scroll_top {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 10;
  padding: 10px 20px;
  background: var(--magenda);
  border-radius: 5px;
  color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.42);
}

.scroll_top i {
  color: var(--white);
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}

@media (max-width: 992px) {
  .head .container {
    flex-direction: column;
    gap: 10px;
  }
}

@media (min-width: 769px) and (max-width: 878px) {
  nav .container {
    width: 95%;
  }
  .menu ul li {
    padding: 10px 5px;
  }
}

@media (max-width: 1168px) {
  .head {
    display: none;
  }
  .menu {
    display: none;
  }
  .menu-btn {
    display: block;
  }
}

@media (max-width : 1499px) {
  .nav-items{
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width : 1080){
  .nav-items{
    justify-content: start;
  }
}

@media (max-width: 576px) {
}