@import url("https://fonts.googleapis.com/css2?family=Cantora+One&family=Kaushan+Script&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
  --magenda: #ed1b69;
  --indigo: #243f86;
  --white: #fff;
  --black: #000;
}

::-webkit-scrollbar {
  width: 0px;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
  text-decoration: none;
  list-style-type: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Ubuntu", sans-serif;
}

.container {
  width: 90%;
  margin: auto auto;
}

.sec_heading {
  text-align: center;
  font-size: 2.25rem;
  padding-bottom: 30px;
  text-transform: capitalize;
  color: var(--magenda);
  position: relative;
}
.sec_heading::before {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  width: 180px;
  height: 3px;
  background: #111;
  transform: translateX(-50%);
}
.sec_heading::after {
  position: absolute;
  bottom: -15px;
  left: 50%;
  font-size: 20px;
  color: var(--indigo);
  padding: 5px;
  background: rgb(255, 255, 255);
  transform: translateX(-50%);
  padding-bottom: 10px;
}
