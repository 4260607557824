.gallery {
  padding-top: 60px;
}

/* .gallery .box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
} */

.gallery .box{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
  justify-items: center;
  justify-self: center;
}

.box .image-container{
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  border-radius: 10px;
}

.box .image-container:hover{
  cursor: pointer;
}

.image-container > img {
  width: 100%;
  height: full;
  object-position: center;
  object-fit: cover;
  aspect-ratio: 2/3;
  transition: transform .5s;
}

.image-container > img:hover{
  transform: scale(1.2);
}

.placeholder{
  background-image: url("../../resource/placeholder.svg");
  width: 10rem;
  height: 10rem;
  aspect-ratio: 2/3;
}

.gallery-popup-img {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.925);
  height: 100%;
  width: 100%;
  z-index: 99999999;
  display: block;
}

.gallery-popup-img span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 40px;
  font-weight: bolder;
  color: var(--magenda);
  cursor: pointer;
  z-index: 999999999;
}

.popup-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.popup-main .popup-content {
  height: 90%;
  width: auto;
  border-radius: 5px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery .box .gallery-img:hover img {
  transform: scale(1.2);
}

.popup-main .popup-content img {
  height: 80%;
  border-radius: 5px;
}
.popup-main .popup-content p {
  font-size: 0.9rem;
  text-align: center;
  width: 90%;
  color: var(--white);
}

.gallery-popup-img button {
  background: transparent;
  outline: none;
  border: none;
}

.gallery-popup-img i {
  font-size: 1.75rem;
  padding: 0 10px;
  color: var(--magenda);
}

@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 992px) {
}
@media (min-width: 769px) and (max-width: 878px) {
}
@media (max-width: 768px) {
    .gallery .box{
      grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .gallery .box > div{
        width: 100%;
      }
}
@media (max-width: 576px) {
    .gallery > .box{
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .popup-main .popup-content{
        height: auto;
        width: 90%;
      }
      .popup-main .popup-content img{
        height: auto;
        width: 100%;
      }
}
