.home_hero{
    width: 100vw;
    height: 90vh;
    background: linear-gradient(to bottom right, #3945924f, #ed196731),
    url("../../resource/Banner/hero-bg2.webp");
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
}

.home_hero .container{
    display: flex;
    align-items: center;
    height: 100%;
}

.home_hero_content{
    width: 500px;
    /* border: 1px solid #000; */
    border-radius: 10px;
    padding: 30px 30px 30px 20px;
    background: #ffffff36;
    backdrop-filter: blur(5px);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.274);
}

.home_hero_content h1{
    font-size: 2rem;
    color: var(--white);
    margin-bottom: 15px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.514);
}

.home_hero_content h3{
    font-size: 1.25rem;
    color: var(--white);
    margin-bottom: 15px;
    font-weight: 500;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.295);
}

.Typewriter span{
    color: var(--magenda);
    font-size: 1.25rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .home_hero{
        height: 60vh;
    }
    .home_hero .container{
        align-items: end;
        padding-bottom: 90px;
    }
}